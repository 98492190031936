<template>
  <div>
    <div class="text-right" id="full-width-section">
      <div class="text-floating-left">GPX編集</div>
      <b-button variant="outline-secondary" class="px-5" @click="$router.push('/menu')">戻る</b-button>
      <b-button variant="primary" class="px-5 ml-4" :disabled="files.length === 0 || validateFileSize" @click="startUpload">決定</b-button>
    </div>

    <div class="mt-3">
      <b-alert variant="danger" :show="!!errors.error">
        <div>{{ errors.error }}</div>
      </b-alert>
    </div>

    <b-container id="kagechi-upload">
      <h5 class="text-left header-top-green">測定したGPXデータをアップロードしてください。</h5>

      <b-alert variant="danger" :show="!!errors.files">
        <p>ファイルが保存できませんでした。以下のメッセージを確認し、再度操作してください。</p>
        <div class="pl-4">{{ errors.files }}</div>
      </b-alert>

      <b-list-group :class="{dragging}" @dragenter="dragging = true" @dragleave="dragging = false" @dragover.prevent="dragging = true" @drop.prevent="selectedFile">
        <b-list-group-item v-if="files.length === 0"  class="empty">
          <div class="top-spacing"><img class="upload-icon" src="../../assets/images/uploading.png"></div>
          ここにファイルをドロップ
        </b-list-group-item>
        <div class="text-center">
          <b-button v-if="files.length === 0" variant="secondary" class="my-4 px-5" @click="onSelectFile">＋ またはファイルを選択</b-button>
          <input type="file" style="display: none;" ref="file" @change="selectedFile" />
        </div>
        <b-list-group-item v-for="(f, index) in files" :key="index" class="d-flex justify-content-between align-items-center" :variant="getValiant(index)">
          <div>
            <div>{{ f.name }}({{ (f.size / 1024).toFixed(2) }}KB)</div>
            <div class="text-danger" v-if="results[index] && !results[index].result">{{ results[index].message }}</div>
          </div>
          <div>
            <b-button variant="light" @click="deleteFile(index)"><b-icon icon="file-earmark-x" aria-hidden="true"/></b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import validation from '../../mixins/validation'

export default {
  name: 'Upload',
  mixins: [validation],
  data: () => ({ files: [], results: [], dragging: false, background: [] }),
  methods: {
    ...mapActions('gpx', ['saveCoordinates', 'saveFiles']),

    onSelectFile () {
      this.$refs.file.click()
    },
    selectedFile (event) {
      this.dragging = false
      let noError = true
      const uploadFiles = event.target.files || event.dataTransfer.files

      // 2つ以上ファイルをdragした場合
      if (uploadFiles.length > 1) {
        this.errors = { error: 'GPXファイルは複数アップロードに対応しておりません。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }

      // すでにfile存在した場合
      if (uploadFiles && this.files.length > 0) {
        this.errors = { error: 'GPXファイルは複数アップロードに対応しておりません。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }

      for (let i = 0; i < uploadFiles.length; i++) {
        if (!uploadFiles[i].name.endsWith('.gpx')) {
          // 拡張子チェック
          noError = false
          this.errors = { error: 'アップロードできるファイルはGPXファイルのみです。' }
          window.scrollTo({ top: 0, behavior: 'smooth' })
        } else if (uploadFiles[i].size === 0) {
          // 空ファイルチェック
          noError = false
          this.errors = { error: '空のファイルはアップロードできません。' }
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }
      }
      if (noError) {
        const makeRequest = (options) => {
          return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest()
            xhr.open(options.method, options.url, true)
            xhr.responseType = 'arraybuffer'
            xhr.onload = () => {
              if (xhr.status === 200) {
                resolve(xhr.response)
              } else {
                reject(new Error(xhr.statusText))
              }
            }
            xhr.onerror = () => {
              reject(new Error(xhr.statusText))
            }
            xhr.send()
          })
        }

        makeRequest({
          method: 'GET',
          url: '/static/wht_background.pdf'
        })
          .then((response) => {
            const result = new Blob([response], { type: 'application/pdf' })
            let backgroundPDF = ''
            for (let i = 0; i < uploadFiles.length; i++) {
              this.files.push(uploadFiles[i])
              backgroundPDF = new File([result], this.files[i].name, { type: 'application/pdf' })
              this.background.push(backgroundPDF)
            }
          })
          .catch(() => {
            this.errors = { error: 'アップロードできませんでした。再度操作してください。' }
            window.scrollTo({ top: 0, behavior: 'smooth' })
          })
      }
    },
    deleteFile (index) {
      this.files.splice(index, 1)
      this.background.splice(index, 1)
    },
    async startUpload () {
      // ファイル数チェック
      if (this.files.length > 1) {
        this.errors = { error: 'GPXファイルは複数アップロードに対応しておりません。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }

      // 拡張子チェック
      let extValidation = false
      this.files.forEach((f) => {
        const splitFileName = f.name.split('.')
        const fileExt = splitFileName[splitFileName.length - 1]
        if (fileExt !== 'gpx') {
          extValidation = true
        }
      })
      if (extValidation) {
        this.errors = { error: 'アップロードできるファイルはGPXファイルのみです。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }

      this.$emit('loading', true)

      const result = await this.readGpxFile(this.files[0])
      this.saveCoordinates(result)
      this.saveFiles(this.files)
      this.$emit('loading', false)
      this.$router.push({ path: '/kagechi/editGPX' })
    },
    getValiant (index) {
      if (!this.results[index]) {
        return ''
      }

      if (this.results[index].result) {
        return 'success'
      }

      return 'danger'
    },
    readGpxFile (f) {
      const resultCoordinate = {}
      let parseResult = ''
      let wpt = ''
      const gpxreader = new FileReader()
      const domParser = new DOMParser()

      return new Promise((resolve, reject) => {
        gpxreader.onload = () => {
          parseResult = domParser.parseFromString(gpxreader.result, 'text/xml')
          wpt = parseResult.getElementsByTagName('wpt')
          if (wpt.length < 2) {
            this.errors = { error: '2点以上のデータを入力してください。' }
            window.scrollTo({ top: 0, behavior: 'smooth' })
            return
          }
          for (let i = 0; i < wpt.length; i++) {
            resultCoordinate['0_' + i] = {
              fileIndex: 0,
              crdnIndex: i,
              coordinates: {
                lat: wpt[i].getAttribute('lat'),
                lon: wpt[i].getAttribute('lon')
              }
            }
          }
          resolve(resultCoordinate)
        }
        gpxreader.onerror = (e) => reject(e)
        gpxreader.readAsText(f)
      })
    }
  },
  computed: {
    validateFileSize () {
      let validFileSize = false
      this.files.forEach((file) => {
        if (file.size < 1) {
          validFileSize = true
        }
      })
      return validFileSize
    }
  }
}
</script>

<style scoped>
</style>
